*{
	box-sizing: border-box;
}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-style: normal;
    list-style: none;
}
cite, code{
	display: inline-block;
	font-style: normal;
}
button{
	cursor: pointer;
}
body {
    line-height: 1;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,label { 
    display: block;
}
ul, ol {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    cursor: pointer;
    text-decoration: none;
}
/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration: none;
}
/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style: italic;
    font-weight: bold;
}
del {
    text-decoration: line-through;
}
abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
input, select {
    outline: none;
    vertical-align:middle;
}