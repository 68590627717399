@font-face {
    font-family: "Roboto";
    src: url('^assets/fonts/Roboto-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('^assets/fonts/Roboto-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("^assets/fonts/RobotoSlab-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Slab";
    src: url('^assets/fonts/RobotoSlab-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
