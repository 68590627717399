.m-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0 !important;
}

.op-75{
  opacity: 0.75;
}

.p-0 {
  padding: 0 !important;
}

.p-b-70{
  padding-bottom: 70px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-28{
  margin-bottom: 28px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-tb-15 {
  margin: 15px 0;
}

.m-tb-20 {
  margin: 20px 0;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.m-t-0{
  margin-top: 0px !important;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}
.m-t-60{
  margin-top: 60px;
}
.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}
.m-t-50{
  margin-top: 50px;
}
.m-b-35 {
  margin-bottom: 35px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-26 {
  margin-top: 26px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-30 {
  padding-top: 30px;
}

.pos-relative {
  position: relative;
}

.mtop-15 {
  margin-top: 15px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.reset-margin {
  margin: 0px !important;
}

.inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.float-left {
  @extend %left;
}

.float-right {
  @extend %right;
}

.clearfix {
  @extend %clearfix;
}

.v-align {
  @extend %v-align;
}

.col-4 {
  @include respond-to(tablet){
    width: 50% !important;
  }
  @include respond-to(mobile){
    width: 100% !important;
  }
}
.col-6 {
  @include respond-to(tablet){
    width: 100% !important;
  }
}

// .heading-level1{
//   color: $primary-text-color;
//   font-size: 26px;
// }
// .heading-level2{
//   color: $primary-text-color;
//   font-size: 22px;
// }
// .heading-level3{
//   color: $primary-text-color;
//   font-size: 16px;
// }

.title-level1 {
  color: $secondary-text-color;
  font-size: 22px;
  line-height: 29px;
  text-transform: capitalize;
}
.title-level2 {
  color: rgba($primary-text-color, 0.75);
  font-size: 18px;
  line-height: 23px;
}

.title-level3 {
  color: rgba($primary-text-color, 0.75);
  font-size: 16px;
  line-height: 21px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  @extend %clearfix;
}

textarea {
  resize: none;
}

.disabled {
  opacity: 0.5;
  background-color: #f1f1f1;
  pointer-events: none;
  cursor: not-allowed;
}
.disabled-link{
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.readonly{
  background-color: rgba($black, 0.1);
  pointer-events: none;
  cursor: not-allowed;
}
.input-wrap {
  position: relative;
  margin-bottom: 20px;

  label {
    display: block;
    color: rgba($primary-text-color, 0.75);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: 19.6px;
  }

  input,
  textarea {
    width: 100%;
    padding: 16px 18px;
    font-size: 14px;
    color: $primary-text-color;
    border: 1px solid $input-border-color;
    @include border-radius(5px);
    outline: none;
    @include placeholder() {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      color: $primary-text-color--light;
    }
  }

  input:focus,
  textarea:focus {
    @include transition(all 300ms linear);
    border: 1px solid darken($input-border-color, 25%);
  }

  .error-msg {
    position: absolute;
    right: 15px;
    bottom: -16px;
    font-size: 12px;
    color: $error-state-color;
  }

  &.error {
    input {
      border: 1px solid $error-state-color;
    }

    textarea {
      border: 1px solid $error-state-color;
    }

    select {
      border: 1px solid $error-state-color;
    }
  }
}

input[type="file"], input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.no-style {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.file-upload {
  font-size: 14px;
  color: rgba($primary-text-color, 0.75);
  padding: 16px 18px;
  background-color: rgba($primary-text-color, 0.03);
  border: 1px dashed rgba($primary-text-color, 0.45);
  @include border-radius(4px);
  cursor: pointer;
  margin-bottom: 0 !important;

  &--ver1 {
    color: $primary-brand-color !important;
    border: 1px solid $primary-brand-color;
    background: rgba($primary-brand-color, 0.10) url("#{$img-path}/upload-icon.svg") left 20px center no-repeat;
  }

  &--ver2 {
    color: $white;
    padding: 8px 16px;
    border: 1px solid transparent;
    background: $primary-brand-color;
  }
}

.ruler {
  border: 1px solid rgba($border-color, 0.30);
}

.cont-wrapper-layout {
  padding: 25px;
  background: rgba($primary-brand-color, 0.04);
  border: 1px dashed rgba($primary-brand-color, 0.45);
  @include border-radius(4px);
}

.link {
  display: inline-block;
  font-size: 14px;
  color: $link-color;
  font-weight: $medium;

  &--small {
    font-size: 12px;
  }

  &:hover {
    color: darken($link-color, 10%);
  }

  &--arrow-down {
    background: url("#{$img-path}/down-arrow-color.svg") right center no-repeat;
    padding-right: 20px;
  }
}

.partner-link {
  display: inline-block;
  font-size: 14px;
  color: $partner-brand-color;
  font-weight: $medium;

  &--small {
    font-size: 12px;
  }

  &:hover {
    color: darken($link-color, 10%);
  }

  &--arrow-down {
    background: url("#{$img-path}/down-arrow-color.svg") right center no-repeat;
    padding-right: 20px;
  }
}

.main {
  min-height: 100vh;
  padding-top: 40px;
  background-color: $section-bg;
  @include respond-to(tablet){
    padding: 40px 20px 100px;
  }
  @include respond-to(tablet){
    padding: 40px 20px;
  }
}
.right-section{
  @include respond-to(tablet){
		width: 100% !important;
    float: none !important;
    margin-top: 25px;
	}
}
.aside-menu{
  @include respond-to(tablet){
		width: 100% !important;
		float: none !important;
	}
}
.container {
  &-primary {
    max-width: 1140px;
  }

  &-secondary {
    max-width: 750px;
    overflow: auto;
  }
}

.card {
  background-color: $white;
  border: 1px solid #e2e2e2;
  @include border-radius(4px);
  &--pd-10 {
    padding: 10px;
  }
  &--pd-15 {
    padding: 15px;
  }
  &--pd-20 {
    padding: 20px;
  }
  &--pd-25 {
    padding: 25px;
  }
  &--pd-30 {
    padding: 30px;
  }
  &__header {
    padding: 15px 35px;
    border-bottom: 1px solid rgba($border-color, 0.40);
    .title-level2 {
      font-size: 18px;
      color: rgba($primary-text-color, 0.75);
    }
  }
  &__body {
    padding: 35px 35px 0;
  }

  &__footer {
    padding: 25px;
  }

  &-type1 {
    background-color: rgba($primary-text-color, 0.03);
    border: 1px dashed rgba($primary-text-color, 0.45);
    @include border-radius(4px);
  }

  &-type1--reverse {
    cursor: pointer;
    background: rgba($primary-brand-color, 0.04);
    border: 1px dashed rgba($primary-brand-color, 0.45);
    @include border-radius(4px);
  }
}
//alert Notification
.alert-notification {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  @include border-radius(4px);

  .msg {
    width: 80%;
    font-size: 14px;
    font-weight: $medium;
    line-height: 19px;
    @include word-wrap();
  }

  &--warning {
    color: $error-state-color;
    background-color: rgba($error-state-color, 0.10);
    border: 1px solid $error-state-color;
    background: url("#{$img-path}/warning.svg") right 12px center no-repeat;
  }

  &--error {
    color: $error-state-color;
    background-color: rgba($error-state-color, 0.10);
    border: 1px solid $error-state-color;
  }

  &--success {
    color: $success-state-color;
    background-color: rgba($success-state-color, 0.10);
    border: 1px solid $success-state-color;
    background: url("#{$img-path}/success-circle.svg") right 12px center no-repeat;
  }
}
// custom checkbox
.custom-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0;

  & + label {
    position: relative;
    color: rgba($primary-text-color, 0.75);
    font-size: 14px;
    font-weight: $medium;
    cursor: pointer;
  }
  // Box.
  & + label:before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    background-color: $white;
    border: 2px solid $primary-text-color--light;
    @include border-radius(3px);
  }
  // Box hover
  &:hover + label:before {
    background-color: $primary-brand-color;
    border: 2px solid transparent;
  }

  &:checked + label,
  &:hover + label {
    color: $primary-text-color;
  }
  // Box checked
  &:checked + label:before {
    background-color: $primary-brand-color;
    border: 2px solid transparent;
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 7px;
    width: 16px;
    height: 12px;
    background: url("#{$img-path}/checked-icon.svg") no-repeat;
    background-size: 13px;
  }
}
// customozing select input box
.custom-select {
  width: 100%;
  position: relative;

  select {
    display: block;
    width: 100%;
    font-size: 14px;
    color: $primary-text-color;
    padding: 16px 30px 16px 18px;
    outline: none;
    background: $white url("#{$img-path}/down-arrow.svg") right 10px center no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $input-border-color;
    @include border-radius(5px);
    cursor: pointer;

    &:focus {
      @include transition(all 300ms linear);
      border: 1px solid darken($input-border-color, 25%);
    }
  }
  &--lg{
    select{
      padding: 16px 30px 16px 18px;
      width: 174px;
    }
  }
}
// word count
.word-count {
  color: rgba($primary-text-color, 0.45);
  font-size: 12px;
  font-weight: $medium;
  position: absolute;
  right: 15px;
  bottom: -17px;
}

.or-wrap {
  position: relative;
  text-align: center;
  .or-txt {
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    color: #8d8d8d;
    font-weight: $bold;
    background-color: $white;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($border-color, 0.5);
  }
}
//table
.table{
    &-wrapper{
        background-color: $white;
        border: 1px solid #ececec;
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.10);
        &.disable{
            background-color: #f3f3f3;
            opacity: 0.5;
            pointer-events: none;
            position: relative;
            &:after{
                // position: absolute;
                // content: "";
                // width: 100%;
                // height: 100%;
                // background-color: rgba(243, 243, 243, 0.5);
                // left: 0;
                // top: 0;
                // pointer-events: none;
                // cursor: not-allowed;
            }
        }
        &--type2{
            background-color: $primary-brand-color;
            .table-container{
                .header-primary{
                    border-bottom: 1px dashed rgba($white, 0.2);
                    .heading{
                        color: rgba($white, 0.75);
                    }
                    .sub-heading{
                        color: $white;
                    }
                }
                .add-resident{
                    padding: 20px 0 50px;
                    background: url(#{$img-path}/white-arrow-design-left.png) left 0px top 12px no-repeat, url(#{$img-path}/white-arrow-design-right.png) right 30px top 40px no-repeat;
                    @include respond-to(mobile){
                        background: none;
                    }
                    .title-level3{
                        color: $white;
                        font-size: 18px;
                        font-weight: $bold;
                        margin-bottom: 8px;
                    }
                    .desc{
                        color: rgba($white, 0.75);
                        font-size: 14px;
                        font-weight: $light;
                        line-height: 18px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
        &--responsive{
            @include respond-to(tablet){
                overflow-x: auto;
            }
            .table-container{
                @include respond-to(tablet){
                    width: 1024px;
                }
            }
        }
    }
    &-container{
        width: 100%;
        text-align: left;
        .header-primary{
            td{
                padding: 25px 15px;
            }
            .heading{
                color: rgba($primary-text-color, 0.45);
                font-size: 12px;
                margin-bottom: 8px;
            }
            .sub-heading{
                font-size: 14px;
                font-weight: $medium;
            }
        }
        .header-secondary{
            tr{
                background-color: #e7e9f0;
            }
            th{
                color: rgba($primary-text-color, 0.45);
                font-size: 12px;
                font-weight: $medium;
                text-transform: uppercase;
                letter-spacing: 1.2;
                padding: 12px 15px;
                line-height: 16.8px;
            }
        }
        tbody{
            tr{
                border-bottom: 1px solid #ececec;
            }
            td{
                color: rgba($primary-text-color, 0.75);
                font-size: 14px;
                font-weight: $medium;
                padding: 15px 15px;
                line-height: 19.6px;
                .sub-cont{
                    display: block;
                    color: rgba($primary-text-color, 0.45);
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .icon-wrap{
                width: 35px;
                height: 35px;
                @include border-radius(100%);
                background: rgba($primary-brand-color, 0.2) url(#{$img-path}/email.svg) center no-repeat;
                cursor: pointer;
                @include transition(background-color 300ms ease-in-out);
                &:hover{
                    background-color: rgba($primary-brand-color, 0.3);
                }
                &--edit{
                    background: rgba($primary-brand-color, 0.2) url(#{$img-path}/edit-icon-color.svg) center no-repeat;
                }
                &--msg{
                    background: rgba($primary-brand-color, 0.2) url(#{$img-path}/email.svg) center no-repeat;
                }
                &--time{
                    background: rgba($primary-brand-color, 0.2) url(#{$img-path}/waitlist.svg) center no-repeat;
                }
                &--schedule{
                    background: rgba($primary-brand-color, 0.2) url(#{$img-path}/calendar.svg) center no-repeat;
                }
                &--delete{
                    background: rgba($primary-brand-color, 0.2) url(#{$img-path}/delete-icon.svg) center no-repeat;
                }
            }
        }
    }
}

// Profile DP
.profile-pic {
  @include avatar(64px, 64px, #d9d9d9, 100%);
  text-align: center;
  line-height: 64px;

  > img {
    @include avatar(18px, 20px, tranparent, 0);
  }

  &--updated {
    > img {
      @include avatar(100%, 100%, tranparent, 100%);
      object-fit: cover;
    }
  }
}
// Ratings
.rating {
  width: 115px;

  label {
    @extend %right;
    position: relative;
    width: $star-size;
    height: $star-size;
    cursor: pointer;
    margin-right: 6px;

    &:not(:first-of-type) {
      padding-right: $star-spacing;
    }

    &:before {
      content: "\2605";
      font-size: $star-size + 2px;
      color: $star-off-color;
      line-height: 1;
    }
  }

  input {
    display: none;
  }

  &:not(:checked) > label:hover:before,
  &:not(:checked) > label.active:before,
  &:not(:checked) > label:hover ~ label:before,
  input:checked ~ label:before {
    color: $star-on-color;
  }

  &--filled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.upload-image {
  &-wrapper {
    padding: 15px;
    background-color: #f8f8f9;
    min-height: 168px;
    @include border-radius(3px);
    .heading {
      color: rgba(22, 22, 22, 0.45);
      font-size: 12px;
      font-weight: $medium;
      line-height: 14px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      span{
        display: flex;
        span.upload-percent{
          color: $primary-text-color;
          font-weight: 500;
          margin-right: 10px;
          margin-top: 4px;
        }
      }
    }
  }

  &-container {
    @extend %clearfix;
  }

  &-list {
    position: relative;
    @extend %left;
    @include avatar(71px, 71px, transparent, 8px);
    background: url("#{$img-path}/facility-pic.png") center / cover no-repeat;
    border: 2px solid $secondary-brand-color;
    margin-right: 15px;
    margin-bottom: 10px;
    .close-icon {
      position: absolute;
      @include avatar(18px, 18px, $primary-text-color, 100%);
      top: -8px;
      right: -7px;
      background: $primary-text-color url(#{$img-path}/modal-close.svg) center no-repeat;
      background-size: 6px;
    }
    &.video{
      &:hover{
        cursor: pointer;
      }
    }
    .file_name{
      margin-top: 80px;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 15px;
    }
  }
}

.cont {
  color: rgba($primary-text-color, 0.75);
  font-size: 14px;
  padding-top: 10px;
  font-weight: $light;
  line-height: 19.5px;
}

.loader-wrapper{
  display: flex;
  justify-content: center;
  padding: 40px;
}


// radio button
.radio-group{
    padding: 12px;
    // border: 1px solid rgba($primary-text-color, 0.2);
    background-color: $white;
    @include border-radius(4px);
    &.active{
        background-color: rgba($primary-brand-color, 0.10);
        border: 1px solid $primary-brand-color;
    }
    [type="radio"]:checked,
        [type="radio"]:not(:checked) {
        position: absolute;
        opacity: 0;
        margin: 0;
        padding: 0;
        width: initial;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        display: inline-block;
        color: rgba($primary-text-color, 0.75);
        font-size: 14px;
        font-weight: $medium;
        line-height: 16px;
        padding-left: 45px;
        margin: 0;
        cursor: pointer;
    }
    [type="radio"]:checked + label{
        color: $primary-text-color;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        @include avatar(16px, 16px, transparent, 100%);
        border: 1px solid $border-color;
        background-color: $border-color;
    }
    [type="radio"]:checked + label:before{
        background-color: rgba($primary-brand-color, 0.10);
        border: 1px solid $primary-brand-color;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        @include avatar(12px, 12px, $primary-brand-color, 100%);
        @include transition(all 300ms linear);
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
span.video-file-name{
  position: absolute;
  top: 72px;
  font-size: 14px;
}
// toggle-slider
.toggle-slider{
    &__checkbox{
        width: initial;
        position: absolute;
        opacity: 0;
        &:checked + label .toggle-slider__ball{
            background: #6ed300;
            &:before{
                left: 25px;
            }
        }
        &:checked + label .toggle-slider__txt{
            color: $primary-text-color;
        }
    }
    &__label{
        display: inline-block;
        vertical-align: middle;
    }
    &__ball{
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 20px;
        background: $gray;
        border-radius: 22px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        position: relative;
        transition: all .4s ease;
        &:before{
            content: "";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $white;
            box-shadow: inset 0 1px 4px 0 rgba(221,214,214,0.5), 0 0 2px 0 rgba(36,16,16,0.4);
            position: absolute;
            top: 2px;
            left: 0;
            transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    &__txt{
        color: rgba($primary-text-color, 0.8);
        font-size: 14px;
        margin-left: 15px;
        cursor: pointer;
        &:hover{
            color: rgba($primary-text-color, 1);
        }
    }
}

.title-white{
  font-size: 16px;
  color: $white;
}

.text-capitalize{
  text-transform: capitalize;
}

.text-blur{
  filter: blur(5px);
}

.password {
  .eye-icon {
    position: absolute;
    right: 20px;
    top: 45px;
    color: #cbcbcb;
    font-size: 23px;
    &.open {
      color: black;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.hide{
  display: none;
}

.swal-title{
  font-size: 22px;
  font-weight: 600;
}

.swal-desc{
  font-size: 16px !important;
  word-break: break-word !important;
}
