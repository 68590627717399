.btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 15px;
    color: $primary-brand-color;
    font-size: 15px;
    font-weight: $medium;
    line-height: 18px;
    background-color: transparent;
    border: 1px solid $primary-brand-color;
    @include border-radius(4px);
    text-align: center;
    cursor: pointer;
    @include transition(all 300ms ease-in-out);
    &:hover {
      background-color: $primary-brand-color;
      color: $white;
      border: 1px solid transparent;
    }
    &--default{
        color: rgba($primary-text-color, 0.5);
        border: 1px solid rgba($border-color, 0.8);
        background-color: rgba($gray, 0.10);
        &:hover{
            color: rgba($primary-text-color, 0.5);
            border: 1px solid rgba($border-color, 0.8);
            background-color: rgba($gray, 0.30);
        }
    }
    &--primary{
        background-color: $primary-brand-color;
        color: $white;
        border: 1px solid transparent;
        &:hover{
            background-color: $primary-brand-color;
            color: $white;
            border: 1px solid transparent;
            box-shadow: 0 15px 30px 0 rgba(0,0,0,0.20);
        }
    }
    &--partner-primary{
        background-color: $partner-brand-color;
        color: $white;
        border: 1px solid transparent;
        &:hover{
            background-color: $partner-brand-color;
            color: $white;
            border: 1px solid transparent;
            box-shadow: 0 15px 30px 0 rgba(0,0,0,0.20);
        }
    }
    &--secondary{
        border: 1px solid $primary-brand-color;
        background-color: rgba($primary-brand-color, 0.10);
        color: $primary-brand-color;
        &:hover{
            border: 1px solid $primary-brand-color;
            background-color: rgba($primary-brand-color, 0.2);
            color: $primary-brand-color;
        }
        &.inverse{
            width: 272px;
            color: $white;
            background: rgba($white, 0.10) url(#{$img-path}/white-arrow-next.svg) right 20px center no-repeat;
            border: 1px solid $white;
            @include border-radius(25px);
            &:hover{
              background: rgba($white, 0.20) url(#{$img-path}/white-arrow-next.svg) right 20px center no-repeat;
            }
        }
    }
    &--partner-secondary{
        border: 1px solid $partner-brand-color;
        background-color: rgba($partner-brand-color, 0.10);
        color: $partner-brand-color;
        &:hover{
            border: 1px solid $partner-brand-color;
            background-color: rgba($partner-brand-color, 0.2);
            color: $partner-brand-color;
        }
        &.inverse{
            width: 272px;
            color: $white;
            background: rgba($white, 0.10) url(#{$img-path}/white-arrow-next.svg) right 20px center no-repeat;
            border: 1px solid $white;
            @include border-radius(25px);
            &:hover{
              background: rgba($white, 0.20) url(#{$img-path}/white-arrow-next.svg) right 20px center no-repeat;
            }
        }
    }
    &--cancel{
        color: rgba($primary-text-color, 0.4);
        border: 1px solid $border-color-type1;
        background-color: rgba($gray, 0.10);
        &:hover{
            color: rgba($primary-text-color, 0.4);
            border: 1px solid $border-color-type1;
            background-color: rgba($gray, 0.30);
        }
    }
    &--edit{
        @include border-radius(26px);
        padding: 4px 20px;
        > .edit-icon{
            display: inline-block;
            width: 12px;
            height: 12px;
            position: relative;
            right: 8px;
            background: url(#{$img-path}/edit-icon1.svg) no-repeat;
        }
    }
    &--arrow-back{
        background: rgba($primary-brand-color, 0.10) url(#{$img-path}/back-arrow.svg) left 10px center no-repeat;
    }
    &--location{
        background-color: rgba($primary-brand-color, 0.10);
        > span{
            margin-left: 10px;
        }
    }
    &--auto{
        width: initial;
    }
    &--wide{
        padding: 10px 25px;
    }
    &--add-more{
        background: rgba($primary-brand-color, 0.10) url(#{$img-path}/add-plus.svg) left 15px center no-repeat;
        padding-left: 50px;
    }
}
