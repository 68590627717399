.aside-menu{
    .nav{
        &-list{
            &-wrapper{
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    left: 14px;
                    top: 30px;
                    bottom: 0;
                    width: 1px;
                    height: 90%;
                    background-color: #dedede;
                }
            }
            &-header{
                margin-bottom: 32px;
                .icon-wrapper{
                    @include avatar(30px, 30px, $primary-brand-color, 100%);
                    background: $primary-brand-color url(#{$img-path}assited-living-home.svg) center no-repeat;
                }
                .title-level2{
                    font-size: 16px;
                    margin-left: 10px;
                }
            }
            &__item{
                position: relative;
                padding: 8px 26px 8px 42px;
                @include border-radius(3px);
                margin-bottom: 8px;
                cursor: pointer;
                @include transition(all 300ms ease-in-out);
                &:after{
                    content: "";
                    position: absolute;
                    left: 15px;
                    top: 18px;
                    width: 16px;
                    height: 1px;
                    background-color: #dedede;
                }
                .icon-wrapper{
                    @include avatar(20px, 20px, $secondary-brand-color, 100%);
                    text-align: center;
                    line-height: 20px;
                    opacity: 0.75;
                }
                .desc{
                    font-size: 14px;
                    color: rgba($secondary-brand-color, 0.5);
                    margin-left: 10px;
                }
                &:hover, &.active{
                    background-color: $primary-brand-color;
                    .icon-wrapper{
                        background-color: $white;
                        opacity: 1;
                        > svg #Group{
                            fill: $primary-brand-color;
                        }
                    }
                    .desc{
                        color: $white;
                    }
                }
            }
        }
    }
    .bed-inventory{
        background: $white;
        border: 1px solid #e1e1e1;
        @include border-radius(4px);
        margin-top: 50px;
        &__header{
            padding: 12px 24px 12px 48px;
            background: #eaeaea url(#{$img-path}bed-inventory.svg) left 24px center no-repeat;
            border: 1px solid #e1e1e1;
            .title-level2{
                font-size: 16px;
            }
        }
        &__body{
            .list{
                &-item{
                    padding: 14px 24px;
                    border: 1px solid #e1e1e1;
                    .cont{
                        color: rgba($primary-text-color, 0.75);
                        font-size: 14px;
                    }
                    .bed-count{
                        font-size: 14px;
                        font-weight: $bold;
                    }
                }
            }
        }
    }
    .bed-info-card{
        background: #eaeaea;
        border: 1px solid #e1e1e1;
        padding: 20px 24px;
        .heading{
            display: block;
            font-size: 14px;
            margin-bottom: 8px;
        }
        .desc{
            color: rgba($primary-text-color, 0.45);
            font-size: 12px;
            line-height: 17px;
        }
    }
    .progress{
        &-tracker{
            width: 30px;
        }
    }
    .upgrade-wrap{
        .cont{
            font-size: 14px;
            color: rgba($primary-text-color, 0.45);
            margin-bottom: 16px;
        }
        > .btn--upgrade{
            width: 122px;
            font-size: 14px;
            padding: 5px;
        }
    }
    .need-help{
        .title-level3{
            color: rgba($secondary-text-color, 0.75);
            margin-bottom: 10px;
        }
        .desc{
            color: rgba($primary-text-color, 0.75);
            font-size: 14px;
            line-height: 19px;
            font-weight: $regular;
        }
        &.invite-users{
            .title-level3{
                background: url(#{$img-path}/email-bw.svg) left center no-repeat;
                padding-left: 30px;
            }
        }
    }
    .card{
        .list{
            &__item{
                position: relative;
                color: rgba($primary-text-color, 0.75);
                font-size: 14px;
                font-weight: $medium;
                padding: 16px 16px;
                cursor: pointer;
                margin-bottom: 6px;
                @include transition(all 300ms ease-in-out);
                &:last-child{
                    margin-bottom: 0;
                }
                &.active, &:hover{
                    color: $white;
                    background-color: $primary-brand-color;
                    @include border-radius(3px);
                    .count{
                        background-color: $white;
                        color: $primary-text-color;
                    }
                }
                .count{
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    @include avatar(26px, 26px, rgba($tertiary-brand-color, 0.75), 100%);
                    color: $white;
                    font-size: 11px;
                    font-weight: $bold;
                    text-align: center;
                    line-height: 26px;
                }
            }
        }
    }
    .aside-link{
        display: block;
        padding-left: 30px;
        color: $primary-text-color;
        font-size: 14px;
        font-weight: $medium;
        opacity: 0.45;
        @include transition(opacity 300ms ease-in-out);
        &--edit-facility{
            background: url(#{$img-path}/edit-facility.svg) left center no-repeat;
        }
        &--edit-deactivate-facility{
            background: url(#{$img-path}/lock-icon.svg) left center no-repeat;
            &.disabled {
              opacity: 0.2 !important;
            }
        }
        &--edit-activate-facility{
            background: url(#{$img-path}/lock-open.svg) left center no-repeat;
            opacity: 1;
        }
    }
    .schedule-wrap{
        background-color: $white;
        border: 1px solid #e0e0e0;
        &__header{
            padding: 14px 18px;
            background-color: rgba($primary-text-color, 0.1);
        }
        &__body{
            .list-item{
                padding: 14px 24px;
                border-bottom: 1px solid rgba($border-color, 0.3);
                &.no-border{
                    border: none;
                }
            }
            .cont-left{
                .name{
                    color: rgba($primary-text-color, 0.75);
                    font-size: 14px;
                    font-weight: $medium;
                    margin-bottom: 5px;
                }
                .type{
                    color: rgba($primary-text-color, 0.45);
                    font-size: 12px;
                }
            }
            .cont-right{
                .date, .time{
                    color: rgba($primary-text-color, 0.45);
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .progress{
        &-card{
            background: rgba($primary-brand-color, 0.04);
            border: 1px solid #e1e1e1;
            padding: 20px 24px;
            .heading{
                display: block;
                font-size: 14px;
                margin-bottom: 8px;
            }
            .desc{
                color: rgba($primary-text-color, 0.45);
                font-size: 12px;
                line-height: 17px;
            }
        }
        &-wrap{
            position: relative;
            @include avatar(100%, 10px, #c9c9c9, 4px);
            &--modify{
                .progress-tracker{
                    width: initial;
                }
            }
        }
        &-bar{
            @include avatar(50%, 10px, $primary-brand-color, 4px);
            @include transition(width 300ms ease-in-out);
        }
        &-tracker{
            position: absolute;
            right: 4px;
            bottom: 18px;
            font-size: 12px;
            font-weight: $bold;
        }
    }
    .profile-pic-wrap{
        .about-profile{
            width: calc(100% - 100px);
            margin-left: 15px;
            .name{
                color: $primary-text-color;
                font-size: 16px;
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: auto;
                text-overflow: ellipsis;
            }
            .id{
                color: rgba($primary-text-color, 0.45);
                font-size: 12px;
            }
        }
    }
    .profile-wrap{
        .profile-pic{
            @include avatar(64px, 64px, tranparent, 100%);
            > img{
                @include avatar(100%, 100%, tranparent, 100%);
                object-fit: cover;
            }
        }
        .profile-details{
            margin-left: 16px;
            .name{
                font-size: 16px;
                font-weight: $bold;
                color: #6a6a6a;
                margin-bottom: 8px;
            }
            .desig{
                color: rgba(38, 19, 80, 0.45);
                font-size: 12px;
            }
        }
        .profile-edit-icon{
            @include avatar(23px, 23px, $primary-brand-color, 100%);
            background: $primary-brand-color url(#{$img-path}edit-icon.svg) center no-repeat;
            margin-top: 10px;
            cursor: pointer;
        }
    }
    .nav{
        &-list{
            &:last-child{
                margin-bottom: 0;
            }
            &.open{
                .nav__sub-menu{
                    max-height: 100px;
                    .nav-item{
                        color: rgba($primary-text-color, 0.8);
                    }
                }
            }
            &.active{
                .nav-item{
                    color: $white;
                    background-color: $primary-brand-color;
                }
                .nav-item--icon{
                    background: $primary-brand-color url(#{$img-path}/down-arrow-white.svg) right 20px center no-repeat;
                }
            }
        }
        &-item{
            display: block;
            color: rgba($primary-text-color, 0.8);
            font-size: 14px;
            font-weight: $medium;
            line-height: 16px;
            padding: 15px;
            @include transition(all 300ms);
            &:hover{
                color: $white;
                background-color: $primary-brand-color;
            }
            &--icon{
                background: url(#{$img-path}/down-arrow.svg) right 20px center no-repeat;
                &:hover{
                    background: $primary-brand-color url(#{$img-path}/down-arrow-white.svg) right 20px center no-repeat;
                }
            }
        }
        &__sub-menu{
            max-height: 0;
            overflow: auto;
            @include transition(max-height 300ms);
            .nav-item{
                padding-left: 30px;
                background-color: white !important;
                &:hover{
                    background-color: rgba($primary-brand-color, 0.15) !important;
                }
            }
        }
    }
}
