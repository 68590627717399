$white: #fff;
$black: #000;
$gray: #d8d8d8;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$green: #07c060;
$blue: #0283e5;
$light-red: #f4446b;

$section-bg: #f7f7f7;

$andre-text: #424142;
$andre-highlight: #222b63;


$primary-text-color: #202944;
$secondary-text-color:#2b2b2b;
$tertiary-text-color: #313235;

$primary-text-color--light:rgba(32, 41, 68, 0.45);
$primary-text-color--medium:rgba(32, 41, 68, 0.75);
$secondary-text-color:#2b2b2b;

$primary-brand-color: #566fb8;
$secondary-brand-color: #252f4e;
$tertiary-brand-color: #202944;
$brand-yellow-color: #fecc04;

$partner-brand-color: #2B59C3;

$border-color: #d8d8d8;
$border-color-type1: #c4c4c4;
$border-color--level1: rgba($border-color, 0.1);
$border-color--level3: rgba($border-color, 0.3);

$input-border-color: rgba(170, 170, 170, 0.75);
$link-color: #566fb8;

$success-state-color: #7ed321;
$error-state-color: #ff2222;
$error-state-bg: #d0021b;

/* font weight */
$very-light: 100;
$light: 300;
$light-italic: 300;
$regular: 400;
$regular-italic: 400;
$medium: 500;
$semibold: 600;
$semibold-italic: 600;
$bold: 700;
$bold-italic: 700;

/* img path */
$img-path: '^assets/img';
$img-icons-path: '^assets/img';

/* break points*/
$bp-mobile: 640px;
$lg-mobile: 768px;
$bp-tablet: 1024px;
$bp-large: 1300px;

// Ratings
$stars: 5;
$star-size: 17px;
$star-spacing: 2px;
$star-on-color: #ffd828;
$star-off-color: #e6e6e6;

/* font Path */
$font-path: '^assets/fonts';
