@use 'sass:math';

@for $i from 1 through 12 {
    .col-#{$i} { 
        $val: math.div(100%, 12) * $i;
        width: $val;
        @extend %left;
        padding-left: 15px;
        padding-right: 15px;
    }
}