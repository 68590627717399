/* You can add global styles to this file, and also import other style files */
// base
@import "./styles/helpers/reset.scss";
@import "./styles/helpers/fonts.scss";
@import "./styles/helpers/variable.scss";
@import "./styles/helpers/placeholder.scss";
@import "./styles/helpers/mixins.scss";
@import "./styles/helpers/function.scss";
@import "./styles/helpers/typography.scss";
@import "./styles/components/aside.scss";
//components
@import "./styles/components/button.scss";
@import "./styles/helpers/global.scss";
// Datetime picker
@import "../node_modules/ng-pick-datetime/assets/style/picker.min.css";
// Calendar
@import "../node_modules/angular-calendar/css/angular-calendar.css";
// Social share
// @import '~@ngx-share/button/styles/share-buttons';
// @import '~@ngx-share/button/styles/themes/default/default-theme';
// ngx datatable
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
// Material icons
@import '../node_modules/material-design-icons/iconfont/material-icons.css';
